// 挂载变量
import {
	API_URL,
	IMG_URL
} from '../env.js';
import store from '@/shopro/store';
import http from '@/shopro/request';
import platform from '@/shopro/platform';
import tools from '@/shopro/utils/tools'
// mixin混入
import mixin from '@/shopro/mixins';

// uni方法重写, h5的粘贴板的设置，获取。图片视频的保存
import wxsdk from '@/shopro/wechat/sdk'
import VConsole from 'vconsole'
import '@/shopro/utils/sdk-h5.js'


import wechat from '@/shopro/wechat/wechat';

const install = Vue => {
	Vue.prototype.$API_URL = API_URL;
	Vue.prototype.$IMG_URL = IMG_URL;
	// 挂载请求
	Vue.prototype.$http = http;
	// 平台判断
	Vue.prototype.$platform = platform;
	// 挂载工具函数
	Vue.prototype.$tools = tools;
	Vue.mixin(mixin);


	// 微信H5,sdk挂载
	Vue.prototype.$wxsdk = wxsdk;
	//  调试微信jssdk
	process.env.NODE_ENV === 'development' && new VConsole()

}

export async function init(options) {

	platform.entry();





	await store.dispatch("appInit", options); // 加载商城基本信息
	await store.dispatch("getTemplate", options); // 加载模板数据



	process.env.NODE_ENV === 'development' && store.dispatch("syncPages");
}

export default {
	install
}
