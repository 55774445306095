
import Vue from 'vue'

const locales = {keys(){return []}}
global['____1F82500____'] = true;
delete global['____1F82500____'];
global.__uniConfig = {"easycom":{"^u-(.*)":"@/uview-ui/components/u-$1/u-$1.vue","^shopro-(.*)":"@/components/shopro-$1/shopro-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^uniad$":"@dcloudio/uni-cli-shared/components/uniad.vue","^ad-rewarded-video$":"@dcloudio/uni-cli-shared/components/ad-rewarded-video.vue","^ad-fullscreen-video$":"@dcloudio/uni-cli-shared/components/ad-fullscreen-video.vue","^ad-interstitial$":"@dcloudio/uni-cli-shared/components/ad-interstitial.vue","^ad-interactive$":"@dcloudio/uni-cli-shared/components/ad-interactive.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"},"preloadRule":{"pages/index/index":{"network":"all","packages":["pages/activity","pages/user","pages/goods","pages/app","pages/public","pages/order"]}},"globalStyle":{"navigationBarTextStyle":"black","navigationBarTitleText":"shopro-plus","navigationBarBackgroundColor":"#FFFFFF","backgroundColor":"#FFFFFF"},"tabBar":{"color":"#333","selectedColor":"#a8700d","backgroundColor":"#ffffff","borderStyle":"black","list":[{"pagePath":"pages/index/index","text":"首页","iconPath":"static/images/tabbar/tabbar_home.png","selectedIconPath":"/static/images/tabbar/tabbar_home1.png","redDot":false,"badge":""},{"pagePath":"pages/index/user","text":"我的","iconPath":"static/images/tabbar/tabbar_personal.png","selectedIconPath":"/static/images/tabbar/tabbar_personal1.png","redDot":false,"badge":""}]}};
global.__uniConfig.compilerVersion = '3.4.18';
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '__UNI__1F82500';
global.__uniConfig.appName = '壹票融';
global.__uniConfig.appVersion = '1.0.0';
global.__uniConfig.appVersionCode = '100';
global.__uniConfig.router = {"mode":"history","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {"maps":{"qqmap":{"key":"DFTBZ-OBG3W-FZURM-OKKFY-WBEA5-HKBC4"}}};
global.__uniConfig.qqMapKey = "DFTBZ-OBG3W-FZURM-OKKFY-WBEA5-HKBC4";
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = undefined;
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-index-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/index/index.vue')), 'pages-index-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-category', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/index/category.vue')), 'pages-index-category'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-cart', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/index/cart.vue')), 'pages-index-cart'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-user', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/index/user.vue')), 'pages-index-user'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-index-view', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/index/view.vue')), 'pages-index-view'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-activity-sign-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/activity/sign/index.vue')), 'pages-activity-sign-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-activity-seckill-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/activity/seckill/list.vue')), 'pages-activity-seckill-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-activity-groupon-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/activity/groupon/list.vue')), 'pages-activity-groupon-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-activity-groupon-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/activity/groupon/detail.vue')), 'pages-activity-groupon-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-activity-groupon-my-groupon', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/activity/groupon/my-groupon.vue')), 'pages-activity-groupon-my-groupon'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-activity-discounts-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/activity/discounts/list.vue')), 'pages-activity-discounts-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-score-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/score/list.vue')), 'pages-app-score-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-coupon-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/coupon/list.vue')), 'pages-app-coupon-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-coupon-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/coupon/detail.vue')), 'pages-app-coupon-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-merchant-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/merchant/index.vue')), 'pages-app-merchant-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-merchant-apply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/merchant/apply.vue')), 'pages-app-merchant-apply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-merchant-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/merchant/detail.vue')), 'pages-app-merchant-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-merchant-info', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/merchant/info.vue')), 'pages-app-merchant-info'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-merchant-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/merchant/list.vue')), 'pages-app-merchant-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/index.vue')), 'pages-app-commission-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-team', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/team.vue')), 'pages-app-commission-team'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-commission-log', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/commission-log.vue')), 'pages-app-commission-commission-log'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-order', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/order.vue')), 'pages-app-commission-order'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-goods', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/goods.vue')), 'pages-app-commission-goods'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-apply', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/apply.vue')), 'pages-app-commission-apply'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-rankings', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/rankings.vue')), 'pages-app-commission-rankings'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-app-commission-share-log', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/app/commission/share-log.vue')), 'pages-app-commission-share-log'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-goods-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/goods/list.vue')), 'pages-goods-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-goods-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/goods/detail.vue')), 'pages-goods-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-goods-comment-add-comment', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/goods/comment/add-comment.vue')), 'pages-goods-comment-add-comment'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-goods-comment-comment-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/goods/comment/comment-list.vue')), 'pages-goods-comment-comment-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-confirm', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/confirm.vue')), 'pages-order-confirm'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-payment-method', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/payment/method.vue')), 'pages-order-payment-method'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-payment-result', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/payment/result.vue')), 'pages-order-payment-result'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/list.vue')), 'pages-order-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/detail.vue')), 'pages-order-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-after-sale-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/after-sale/detail.vue')), 'pages-order-after-sale-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-after-sale-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/after-sale/list.vue')), 'pages-order-after-sale-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-after-sale-log', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/after-sale/log.vue')), 'pages-order-after-sale-log'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-after-sale-refund', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/after-sale/refund.vue')), 'pages-order-after-sale-refund'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-express-distribution-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/express/distribution-detail.vue')), 'pages-order-express-distribution-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-express-express-detail', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/express/express-detail.vue')), 'pages-order-express-express-detail'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-express-express-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/express/express-list.vue')), 'pages-order-express-express-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-order-express-store-address', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/order/express/store-address.vue')), 'pages-order-express-store-address'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-faq', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/faq.vue')), 'pages-public-faq'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-feedback', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/feedback.vue')), 'pages-public-feedback'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-chat-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/chat/index.vue')), 'pages-public-chat-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-search', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/search.vue')), 'pages-public-search'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-richtext', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/richtext.vue')), 'pages-public-richtext'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-webview', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/webview.vue')), 'pages-public-webview'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-404', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/404.vue')), 'pages-public-404'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-public-loading', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/public/loading.vue')), 'pages-public-loading'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-info', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/info.vue')), 'pages-user-info'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-set', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/set.vue')), 'pages-user-set'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-apply-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/apply-list.vue')), 'pages-user-apply-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-apply-info', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/apply-info.vue')), 'pages-user-apply-info'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-renzhen', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/renzhen.vue')), 'pages-user-renzhen'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-huankuan', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/huankuan.vue')), 'pages-user-huankuan'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-huankuan-info', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/huankuan-info.vue')), 'pages-user-huankuan-info'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-view-log', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/view-log.vue')), 'pages-user-view-log'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-wallet-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/wallet/index.vue')), 'pages-user-wallet-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-wallet-withdraw', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/wallet/withdraw.vue')), 'pages-user-wallet-withdraw'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-wallet-withdraw-log', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/wallet/withdraw-log.vue')), 'pages-user-wallet-withdraw-log'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-wallet-top-up', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/wallet/top-up.vue')), 'pages-user-wallet-top-up'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-wallet-top-up-log', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/wallet/top-up-log.vue')), 'pages-user-wallet-top-up-log'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-wallet-score-balance', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/wallet/score-balance.vue')), 'pages-user-wallet-score-balance'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-address-list', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/address/list.vue')), 'pages-user-address-list'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-address-edit', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/address/edit.vue')), 'pages-user-address-edit'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-user-favorite', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require('/Applications/phpstudy/web/壹票融/src/pages/user/favorite.vue')), 'pages-user-favorite'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/index/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,
isTabBar:true,

          
          tabBarIndex:0
        },__uniConfig.globalStyle,{"navigationBarTitleText":"首页","navigationBarTextStyle":"white","enablePullDownRefresh":true,"navigationStyle":"custom","mp-alipay":{"transparentTitle":"always","titlePenetrate":"YES","allowsBounceVertical":"NO"}})
      },
      [
        createElement('pages-index-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-index-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/index',
isQuit:true,
isEntry:true,
isTabBar:true,
tabBarIndex:0,
  windowTop:0
}
},
{
path: '/pages/index/category',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分类"})
      },
      [
        createElement('pages-index-category', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-category',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/category',
  windowTop:44
}
},
{
path: '/pages/index/cart',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"购物车"})
      },
      [
        createElement('pages-index-cart', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-cart',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/cart',
  windowTop:44
}
},
{
path: '/pages/index/user',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isTabBar:true,

          
          tabBarIndex:1
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的","navigationStyle":"custom","navigationBarTextStyle":"white","enablePullDownRefresh":true})
      },
      [
        createElement('pages-index-user', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:2,
  name:'pages-index-user',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/user',
isQuit:true,
isTabBar:true,
tabBarIndex:1,
  windowTop:0
}
},
{
path: '/pages/index/view',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","enablePullDownRefresh":true})
      },
      [
        createElement('pages-index-view', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-index-view',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/view',
  windowTop:44
}
},
{
path: '/pages/activity/sign/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"签到中心"})
      },
      [
        createElement('pages-activity-sign-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-activity-sign-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/activity/sign/index',
  windowTop:44
}
},
{
path: '/pages/activity/seckill/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"限时秒杀","enablePullDownRefresh":true})
      },
      [
        createElement('pages-activity-seckill-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-activity-seckill-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/activity/seckill/list',
  windowTop:44
}
},
{
path: '/pages/activity/groupon/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"今日必拼","enablePullDownRefresh":true})
      },
      [
        createElement('pages-activity-groupon-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-activity-groupon-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/activity/groupon/list',
  windowTop:44
}
},
{
path: '/pages/activity/groupon/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"拼团详情","enablePullDownRefresh":true})
      },
      [
        createElement('pages-activity-groupon-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-activity-groupon-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/activity/groupon/detail',
  windowTop:44
}
},
{
path: '/pages/activity/groupon/my-groupon',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的拼团","enablePullDownRefresh":true})
      },
      [
        createElement('pages-activity-groupon-my-groupon', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-activity-groupon-my-groupon',
  isNVue:false,maxWidth:0,
  pagePath:'pages/activity/groupon/my-groupon',
  windowTop:44
}
},
{
path: '/pages/activity/discounts/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"优惠活动商品"})
      },
      [
        createElement('pages-activity-discounts-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-activity-discounts-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/activity/discounts/list',
  windowTop:44
}
},
{
path: '/pages/app/score/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分商品"})
      },
      [
        createElement('pages-app-score-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-score-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/score/list',
  windowTop:44
}
},
{
path: '/pages/app/coupon/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"优惠券中心","navigationStyle":"custom"})
      },
      [
        createElement('pages-app-coupon-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-coupon-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/coupon/list',
  windowTop:0
}
},
{
path: '/pages/app/coupon/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"优惠券详情","navigationStyle":"custom"})
      },
      [
        createElement('pages-app-coupon-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-coupon-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/coupon/detail',
  windowTop:0
}
},
{
path: '/pages/app/merchant/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"门店中心","navigationStyle":"custom","enablePullDownRefresh":true,"navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-app-merchant-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-merchant-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/merchant/index',
  windowTop:0
}
},
{
path: '/pages/app/merchant/apply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"门店入驻","navigationStyle":"custom"})
      },
      [
        createElement('pages-app-merchant-apply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-merchant-apply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/merchant/apply',
  windowTop:0
}
},
{
path: '/pages/app/merchant/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"订单详情"})
      },
      [
        createElement('pages-app-merchant-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-merchant-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/merchant/detail',
  windowTop:44
}
},
{
path: '/pages/app/merchant/info',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"门店详情"})
      },
      [
        createElement('pages-app-merchant-info', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-merchant-info',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/merchant/info',
  windowTop:44
}
},
{
path: '/pages/app/merchant/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的门店"})
      },
      [
        createElement('pages-app-merchant-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-merchant-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/merchant/list',
  windowTop:44
}
},
{
path: '/pages/app/commission/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分销中心","enablePullDownRefresh":true,"navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-app-commission-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/index',
  windowTop:0
}
},
{
path: '/pages/app/commission/team',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的团队","navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-app-commission-team', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-team',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/team',
  windowTop:0
}
},
{
path: '/pages/app/commission/commission-log',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"佣金明细","enablePullDownRefresh":true})
      },
      [
        createElement('pages-app-commission-commission-log', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-commission-log',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/commission-log',
  windowTop:44
}
},
{
path: '/pages/app/commission/order',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分销订单","navigationStyle":"custom","navigationBarTextStyle":"white","enablePullDownRefresh":true})
      },
      [
        createElement('pages-app-commission-order', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-order',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/order',
  windowTop:0
}
},
{
path: '/pages/app/commission/goods',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"推广商品"})
      },
      [
        createElement('pages-app-commission-goods', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-goods',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/goods',
  windowTop:44
}
},
{
path: '/pages/app/commission/apply',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"申请分销商","navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-app-commission-apply', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-apply',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/apply',
  windowTop:0
}
},
{
path: '/pages/app/commission/rankings',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分销排行","navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-app-commission-rankings', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-rankings',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/rankings',
  windowTop:0
}
},
{
path: '/pages/app/commission/share-log',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"分享记录","navigationStyle":"custom","navigationBarTextStyle":"white","enablePullDownRefresh":true})
      },
      [
        createElement('pages-app-commission-share-log', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-app-commission-share-log',
  isNVue:false,maxWidth:0,
  pagePath:'pages/app/commission/share-log',
  windowTop:0
}
},
{
path: '/pages/goods/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品列表","navigationStyle":"custom"})
      },
      [
        createElement('pages-goods-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-goods-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/goods/list',
  windowTop:0
}
},
{
path: '/pages/goods/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"商品详情"})
      },
      [
        createElement('pages-goods-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-goods-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/goods/detail',
  windowTop:44
}
},
{
path: '/pages/goods/comment/add-comment',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"评价"})
      },
      [
        createElement('pages-goods-comment-add-comment', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-goods-comment-add-comment',
  isNVue:false,maxWidth:0,
  pagePath:'pages/goods/comment/add-comment',
  windowTop:44
}
},
{
path: '/pages/goods/comment/comment-list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"评价列表"})
      },
      [
        createElement('pages-goods-comment-comment-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-goods-comment-comment-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/goods/comment/comment-list',
  windowTop:44
}
},
{
path: '/pages/order/confirm',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"确认订单"})
      },
      [
        createElement('pages-order-confirm', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-confirm',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/confirm',
  windowTop:44
}
},
{
path: '/pages/order/payment/method',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"收银台"})
      },
      [
        createElement('pages-order-payment-method', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-payment-method',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/payment/method',
  windowTop:44
}
},
{
path: '/pages/order/payment/result',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"支付结果"})
      },
      [
        createElement('pages-order-payment-result', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-payment-result',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/payment/result',
  windowTop:44
}
},
{
path: '/pages/order/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"订单列表"})
      },
      [
        createElement('pages-order-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/list',
  windowTop:44
}
},
{
path: '/pages/order/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"订单详情"})
      },
      [
        createElement('pages-order-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/detail',
  windowTop:44
}
},
{
path: '/pages/order/after-sale/detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"售后详情"})
      },
      [
        createElement('pages-order-after-sale-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-after-sale-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/after-sale/detail',
  windowTop:44
}
},
{
path: '/pages/order/after-sale/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"售后列表"})
      },
      [
        createElement('pages-order-after-sale-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-after-sale-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/after-sale/list',
  windowTop:44
}
},
{
path: '/pages/order/after-sale/log',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"售后记录"})
      },
      [
        createElement('pages-order-after-sale-log', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-after-sale-log',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/after-sale/log',
  windowTop:44
}
},
{
path: '/pages/order/after-sale/refund',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"申请售后"})
      },
      [
        createElement('pages-order-after-sale-refund', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-after-sale-refund',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/after-sale/refund',
  windowTop:44
}
},
{
path: '/pages/order/express/distribution-detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"配送详情"})
      },
      [
        createElement('pages-order-express-distribution-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-express-distribution-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/express/distribution-detail',
  windowTop:44
}
},
{
path: '/pages/order/express/express-detail',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"物流详情"})
      },
      [
        createElement('pages-order-express-express-detail', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-express-express-detail',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/express/express-detail',
  windowTop:44
}
},
{
path: '/pages/order/express/express-list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"包裹列表"})
      },
      [
        createElement('pages-order-express-express-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-express-express-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/express/express-list',
  windowTop:44
}
},
{
path: '/pages/order/express/store-address',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"选择自提点","navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-order-express-store-address', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-order-express-store-address',
  isNVue:false,maxWidth:0,
  pagePath:'pages/order/express/store-address',
  windowTop:0
}
},
{
path: '/pages/public/faq',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"常见问题"})
      },
      [
        createElement('pages-public-faq', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-faq',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/faq',
  windowTop:44
}
},
{
path: '/pages/public/feedback',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"问题反馈"})
      },
      [
        createElement('pages-public-feedback', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-feedback',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/feedback',
  windowTop:44
}
},
{
path: '/pages/public/chat/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"客服","navigationStyle":"custom","navigationBarTextStyle":"white"})
      },
      [
        createElement('pages-public-chat-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-chat-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/chat/index',
  windowTop:0
}
},
{
path: '/pages/public/search',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"搜索"})
      },
      [
        createElement('pages-public-search', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-search',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/search',
  windowTop:44
}
},
{
path: '/pages/public/richtext',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-public-richtext', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-richtext',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/richtext',
  windowTop:44
}
},
{
path: '/pages/public/webview',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-public-webview', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-webview',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/webview',
  windowTop:44
}
},
{
path: '/pages/public/404',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"页面不存在"})
      },
      [
        createElement('pages-public-404', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-404',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/404',
  windowTop:44
}
},
{
path: '/pages/public/loading',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom","enablePullDownRefresh":false})
      },
      [
        createElement('pages-public-loading', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-public-loading',
  isNVue:false,maxWidth:0,
  pagePath:'pages/public/loading',
  windowTop:0
}
},
{
path: '/pages/user/info',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"个人信息"})
      },
      [
        createElement('pages-user-info', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-info',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/info',
  windowTop:44
}
},
{
path: '/pages/user/set',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"系统设置"})
      },
      [
        createElement('pages-user-set', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-set',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/set',
  windowTop:44
}
},
{
path: '/pages/user/apply-list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"认证列表"})
      },
      [
        createElement('pages-user-apply-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-apply-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/apply-list',
  windowTop:44
}
},
{
path: '/pages/user/apply-info',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"申请详情"})
      },
      [
        createElement('pages-user-apply-info', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-apply-info',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/apply-info',
  windowTop:44
}
},
{
path: '/pages/user/renzhen',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"企业认证"})
      },
      [
        createElement('pages-user-renzhen', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-renzhen',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/renzhen',
  windowTop:44
}
},
{
path: '/pages/user/huankuan',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"还款信息"})
      },
      [
        createElement('pages-user-huankuan', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-huankuan',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/huankuan',
  windowTop:44
}
},
{
path: '/pages/user/huankuan-info',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"还款详情"})
      },
      [
        createElement('pages-user-huankuan-info', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-huankuan-info',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/huankuan-info',
  windowTop:44
}
},
{
path: '/pages/user/view-log',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"浏览足迹"})
      },
      [
        createElement('pages-user-view-log', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-view-log',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/view-log',
  windowTop:44
}
},
{
path: '/pages/user/wallet/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的钱包"})
      },
      [
        createElement('pages-user-wallet-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-wallet-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/wallet/index',
  windowTop:44
}
},
{
path: '/pages/user/wallet/withdraw',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom"})
      },
      [
        createElement('pages-user-wallet-withdraw', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-wallet-withdraw',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/wallet/withdraw',
  windowTop:0
}
},
{
path: '/pages/user/wallet/withdraw-log',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"提现记录"})
      },
      [
        createElement('pages-user-wallet-withdraw-log', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-wallet-withdraw-log',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/wallet/withdraw-log',
  windowTop:44
}
},
{
path: '/pages/user/wallet/top-up',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"充值","navigationStyle":"custom"})
      },
      [
        createElement('pages-user-wallet-top-up', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-wallet-top-up',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/wallet/top-up',
  windowTop:0
}
},
{
path: '/pages/user/wallet/top-up-log',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"充值记录"})
      },
      [
        createElement('pages-user-wallet-top-up-log', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-wallet-top-up-log',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/wallet/top-up-log',
  windowTop:44
}
},
{
path: '/pages/user/wallet/score-balance',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"积分余额","navigationStyle":"custom"})
      },
      [
        createElement('pages-user-wallet-score-balance', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-wallet-score-balance',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/wallet/score-balance',
  windowTop:0
}
},
{
path: '/pages/user/address/list',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"收货地址"})
      },
      [
        createElement('pages-user-address-list', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-address-list',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/address/list',
  windowTop:44
}
},
{
path: '/pages/user/address/edit',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":""})
      },
      [
        createElement('pages-user-address-edit', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-address-edit',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/address/edit',
  windowTop:44
}
},
{
path: '/pages/user/favorite',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"我的收藏"})
      },
      [
        createElement('pages-user-favorite', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-user-favorite',
  isNVue:false,maxWidth:0,
  pagePath:'pages/user/favorite',
  windowTop:44
}
},
{
path: '/preview-image',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-preview-image', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'preview-image',
  pagePath:'/preview-image'
}
}
    ,
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
