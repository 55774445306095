/**
 * Wechat v1.1.0
 * @Class Wechat
 * @description shopro-wechat 1.1.0 wehcat第三方登录组件
 * @Author lidongtony
 * @Date 2020-05-20
 * @Email lidongtony@qq.com
 */
import api from '@/shopro/request/index'
import $platform from '@/shopro/platform'
import store from '@/shopro/store'
import {
  API_URL
} from '@/env'

export default {
  eventMap(event) {
    let map = ''
    switch (event) {
      case 'login':
        map = '登录中...'
        break
      case 'refresh':
        map = '更新中...'
        break
      case 'bind':
        map = '绑定中...'
        break
    }
    return map
  },

  async login() {
    let token = ''





    this.wxOfficialAccountOauth('login')





  },
  async refresh() {
    let token = ''





    this.wxOfficialAccountOauth('refresh')





  },
  async bind() {
    let token = ''





    this.wxOfficialAccountOauth('bind')





  },


  // 微信公众号网页登录&刷新头像昵称&绑定
  wxOfficialAccountOauth(event = 'login') {
    if ($platform.get() !== 'wxOfficialAccount') {
      uni.showToast({
        title: '请在微信浏览器中打开',
        icon: 'none'
      })
      throw false
    }
    let host = $platform.host()
    let payloadObject = {
      host: host,
      event,
      token: (event !== 'login' && store.getters.isLogin) ? uni.getStorageSync('token') : ''
    }
    let payload = encodeURIComponent(JSON.stringify(payloadObject))
    let redirect_uri = encodeURIComponent(`${API_URL}user/user/wxOfficialAccountOauth?payload=${payload}`)
    let oauthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + store.getters.initWechat.appid +
      `&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=1`
	// console.log(redirect_uri,oauthUrl)
    uni.setStorageSync('lastPage', window.location.href)
    window.location = oauthUrl
  },

  // 微信公众号网页静默登录:临时登录获取OpenId 不入库不绑定用户
  wxOfficialAccountBaseLogin() {
    let state = encodeURIComponent(window.location.href)
    window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + store.getters.initWechat.appid +
      `&redirect_uri=${API_URL}user/wxOfficialAccountBaseLogin&response_type=code&scope=snsapi_base&state=${state}`
    throw 'stop'
  },





















































































































































}
