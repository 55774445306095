<template><App :keepAliveInclude="keepAliveInclude"/></template>
<script>
	import {
		init
	} from "@/shopro";
	export default {
		onLaunch(options) {
			init(options);
		}
	};
</script>

<style lang="scss">
	@import 'static/style/index.scss';
</style>